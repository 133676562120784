import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { jsx } from '@emotion/core';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import { renderPageSections } from '../renderPageSections';
import useLocalize from '../../../hooks/useLocalize';

const BoardOfDirectorsPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanityBoardOfDirectorsPage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const boardOfDirectorsPageProps = {
    id: data.sanityBoardOfDirectorsPage._id,
    metadata: { ...data.sanityBoardOfDirectorsPage._rawRoute, href },
    header: data.sanityBoardOfDirectorsPage._rawHeader,
    sections: data.sanityBoardOfDirectorsPage._rawSections,
    bubbleCtaSection: data.sanityBoardOfDirectorsPage._rawBubbleCtaSection,
  };

  return (
    <Layout
      locale={locale}
      {...boardOfDirectorsPageProps.header}
      {...boardOfDirectorsPageProps.metadata}
      sanityId={boardOfDirectorsPageProps.id}
    >
      {renderPageSections(boardOfDirectorsPageProps.sections)}
      {boardOfDirectorsPageProps.bubbleCtaSection && (
        <BubbleCtaSection {...boardOfDirectorsPageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

BoardOfDirectorsPage.propTypes = {
  data: PropTypes.shape({ sanityBoardOfDirectorsPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }),
};

BoardOfDirectorsPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: 'us' },
};

export default BoardOfDirectorsPage;

export const query = graphql`
  query($slug: String) {
    sanityBoardOfDirectorsPage(route: { slug: { current: { eq: $slug } } }) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawHeader(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
    }
  }
`;
